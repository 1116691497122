<template>
  <div class="flash-bg">
    <div v-if="data?.ProductSaleStatus == 'ACTIVE' && $h.isBlank(data?.GroupBuyId)" class="flash-text">
      <div class="timer-container">
        <img loading="lazy" class="flash-img" :src="require('@/assets/images/flash-sale-timer-small.png')" />
      </div>
      <p>{{ $t.getTranslation("LBL_FLASH_SALE") }}</p>
    </div>

    <div v-if="data?.GroupBuyId > 0" class="group-text">
      <div class="timer-container">
        <img loading="lazy" class="flash-img" :src="require('@/assets/images/flash-sale-group-small.png')" />
      </div>

      <p>
        {{ $t.getTranslation("LBL_GROUP_BUY") }}
      </p>

      <div class="product-sold">
        <strong>{{ data.TotalProductSold || 0 }}</strong>
        <small>{{ $t.getTranslation("LBL_BOTTLES_SOLD") }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "FlashSaleGroupComponent",
  components: {},
  props: { data: Object },
  setup() {
    const store = useStore();
   
    return {store};
  },
});
</script>

<style scoped>
.flash-bg {
  height: 15px;
  padding-top: 10px;
  background-image: linear-gradient(to right, #0c4d8f, #0080ff);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
}
.timer-container {
  width: 25px;
  margin-top: 5px;
  padding-right: 3px;
}
.flash-img {
  height: 100% !important;
  width: 100% !important;
}

.flash-time {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  font-size: 14px;
}
.flash-time p {
  margin: 0;
}
.flash-text {
  text-transform: uppercase;
  /* font-family: "Racing", sans-serif; */
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.group-text {
  text-transform: uppercase;
  /* font-family: "Racing", sans-serif; */
  font-size: 13px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  width: 100%;
  align-items: center;
}
.group-text p {
  font-family: "Racing", sans-serif;
}
.product-sold {
  position: absolute;
  bottom: -8px;
  right: -8px;
  background: #0c5096;
  display: flex;
  flex-direction: column;
  text-align: right;
  padding: 5px 10px 10px 5px;
  z-index: 99;
  border-radius: 8px 0px 0px 8px;
  text-transform: uppercase;
}
.product-sold strong {
  font-size: 16px;
}
.product-sold small {
  font-size: 7px;
  width: 8ch;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* .group-text strong {
  font-size: 22px;
  color: #ffb400;
  display: block;
  margin-left: auto;
  margin-right: 5px;
} */
.flash-st {
  font-size: 14px;
}
</style>
