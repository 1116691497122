<template>
  <div v-if="data?.GroupBuyId > 0" class="group-bg">
    <div class="flash-text">
      <img class="flash-img" :src="require('@/assets/images/flash-sale-group-small.png')" loading="lazy" />
      <p>{{ $t.getTranslation('LBL_GROUP_BUY') }}</p>

      <!-- <strong>{{ data.GroupBuyMOQ }}</strong> -->
    </div>

    <div class="flash-time">
      <p>{{ $h.formatDateTime(data.StartDate) }} - {{ $h.formatDateTime(data.EndDate) }}</p>
    </div>

    <div class="product-sold">
      <strong>{{ data.TotalProductSold || 0 }}</strong>
      <small>{{ $t.getTranslation('LBL_BOTTLES_SOLD') }}</small>
    </div>
  </div>

  <div v-else class="flash-bg">
    <div class="flash-text">
      <img class="flash-img" :src="require('@/assets/images/flash-sale-timer-small.png')" loading="lazy" />
      <p>{{ $t.getTranslation('LBL_FLASH_SALE') }}</p>
    </div>

    <div class="flash-time">
      <p v-if="!isSale">{{ $t.getTranslation('LBL_START_IN') }} {{ startDay }} {{ startDate }}</p>
      <p>{{ $t.getTranslation('LBL_ENDS_IN') }} {{ endDay }} {{ endDate }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { helpers } from '@/utils/helpers.js'
import { get } from '@/utils/axios'
import { useStore } from "@/store";

export default defineComponent({
  name: 'FlashSaleComponent',
  components: {},
  props: {
    data: Object,
    getProduct: Function
  },
  setup(props) {
    const store = useStore();

    const timer = ref('')
    const totaltime = ref(1)
    const isSale = ref(false)

    const startDate = ref(props.data.SaleFrom)
    const endDate = ref(props.data.SaleTo)
    const startDay = ref(helpers.formatMonthDay(props.data.SaleFrom))
    const endDay = ref(helpers.formatMonthDay(props.data.SaleTo))
    startDate.value = helpers.formatSaleTime(startDate.value)
    endDate.value = helpers.formatSaleTime(endDate.value)
    let toLoop = ref(false)

    let StartTime = new Date(props.data.SaleFrom).getTime()
    let EndTime = new Date(props.data.SaleTo).getTime()

    const formatStartDate = () => {
      return helpers.formatMonthDay(props.data.SaleFrom)
    }

    const formatEndDateDay = () => {
      return helpers.formatMonthDay(props.data.SaleTo)
    }

    const formatEndDate = () => {
      return helpers.formatMonthDay(props.data.SaleFrom)
    }

    const getTimeRemaining = () => {
      const total = Date.parse(props.data.SaleTo) - Date.parse(new Date())
      timer.value = helpers.formatSaleTime(endDate.value)
      totaltime.value = total
      if (currentDate > StartTime && !(currentDate > EndTime)) {
        isSale.value = true
      }
    }

    const checkSale = async () => {
      let ret = await get(`/mobile/product/check/sale?ProductKey=${props.data.ProductKey}`)
      if (ret?.ProductSale) {
        //re-call the parent function
        await props.getProduct()
      }
    }

    onMounted(async () => {
      // let count = setInterval(async () => {
      //   if (props?.data?.ProductSaleStatus) {
      //     clearInterval(count)
      //   }
      //   await checkSale()
      // }, 1000)
      if (props?.data?.ProductSaleStatus == 'ACTIVE') {
        //check if the product is on sale...
        let StartTime = new Date(props.data.SaleFrom).getTime()
        let EndTime = new Date(props.data.SaleTo).getTime()
        let CurrentTime = new Date().getTime()
        if (CurrentTime > StartTime && !(CurrentTime > EndTime)) {
          isSale.value = true
        } else {
          let countSale = setInterval(async () => {
            let StartTime = new Date(props.data.SaleFrom).getTime()
            let EndTime = new Date(props.data.SaleTo).getTime()
            if (CurrentTime > StartTime && !(CurrentTime > EndTime)) {
              isSale.value = true
              //clear time..
              clearInterval(countSale)
            }
          }, 1000)
        }
      }
    })

    return {
      formatStartDate,
      timer,
      formatEndDateDay,
      isSale,
      startDate,
      formatEndDate,
      endDay,
      startDay,
      endDate,
      store
    }
  }
})
</script>

<style scoped>
.flash-bg {
  height: 45px;
  /* background-color: #A71B1A; */
  background-image: linear-gradient(to right, #0c4d8f, #0080ff);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 10px 5px;
  position: relative;
}

.group-bg {
  background-image: linear-gradient(to right, #0c4d8f, #0080ff);
  color: #fff;
  display: flex;
  align-items: flex-start;
  padding: 10px 20px 15px;
  position: relative;
  flex-direction: column;
  height: auto;
  margin-top: -10px;
}
.flash-img {
  width: 30px;
  margin-right: 5px;
}
.flash-time {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  font-size: 14px;
}
.flash-time p {
  margin: 0;
}
.flash-text {
  text-transform: uppercase;
  font-family: 'Racing', sans-serif;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px 10px;
  padding: 0px;
  line-height: 1em;
}
.flash-text p {
  margin: 0px 0px 0px;
  padding: 0px;
  line-height: 1em;
}
.flash-st {
  font-size: 14px;
}

.group-bg .flash-text {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
}
.group-bg .flash-text strong {
  font-size: 30px;
  color: #ffb400;
  display: block;
  margin-left: auto;
  margin-right: 5px;
}
.group-bg .flash-time {
  width: 100%;
}
.group-bg .flash-time p {
  width: 100%;
  text-align: left;
  font-size: 11px;
}

.product-sold {
  position: absolute;
  bottom: 10px;
  right: 0px;
  background: #0c5096;
  display: flex;
  flex-direction: column;
  text-align: right;
  padding: 10px 10px 10px 10px;
  z-index: 99;
  border-radius: 8px 0px 0px 8px;
  text-transform: uppercase;
}
.product-sold strong {
  font-size: 18px;
}
.product-sold small {
  font-size: 8px;
}
</style>
